/* global rp$, window */

const THE_SCRIPT = function() {
	const $onReady = require('public/js/lib/on-ready');
	const {
		getNextStep,
		setVehicle,
		handleRedirect
	} = require('public/js/api/vehicle');

	let dropDownWrapper;
	let dropDowns;

	let defaultMake;

	let saveButton;
	let saveOnButtonClick = false;

	const dropDownWrapperSelector = '.vehicle-picker-dropdown';
	const dropDownSelector = '.dropdown';
	const toggleButtonSelector = '.dropdown-toggle';
	const filterInputSelector = '.dropdown-search-input';
	const loadingClass = 'loading';
	const dropDownOptionClass = 'dropdown-item';
	const dropDownOptionSelector = `.${dropDownOptionClass}`;
	const makeHiddenInputSelector = `input[name="selected_make"]`;
	const saveButtonSelector = `.vehicle-picker-dropdown-submit-button`;

	const OPTION_ATTRIBUTES = {
		value: 'data-value',
		display: 'data-display',
		type: 'data-type',
		label: 'data-label',
		primary_make: 'primary_make',
	};

	// Required option html:
	// <li>
	// 	<button class="dropdown-item" data-type="model" data-value="grand-caravan" data-display="Grand Caravan">Grand Caravan</button>
	// </li>
	function createOption(val, label, type, primary_make = null) {
		const $listItemElem = rp$('<li></li>');
		const $linkElem = rp$('<button></button>');
		$linkElem
		.addClass(dropDownOptionClass)
		.attr(OPTION_ATTRIBUTES.value, val)
		.attr(OPTION_ATTRIBUTES.display, parseEncodedLabel(label))
		.attr(OPTION_ATTRIBUTES.type, type)
		.append(label)
		.on('click', handleOptionClick);
		if(primary_make !== null)
		{
			$linkElem.attr(OPTION_ATTRIBUTES.primary_make, primary_make);	 
		}
		$listItemElem.append($linkElem);

		return $listItemElem;
	}

	function parseEncodedLabel(label) {
		let result = label;

		if (typeof window.DOMParser !== 'undefined') {
			const doc = new window.DOMParser().parseFromString(label, 'text/html');
			result = doc.documentElement.textContent;
		}

		return result;
	}

	function addDataToList($elem, data) {
		const type = getDropDownType($elem);
		if(type === 'make'){
			
			const primary_make = data.filter( item => parseInt(item.primary_make) );
			const secondary_make = data.filter( item => !parseInt(item.primary_make) );

			for (const prop in primary_make) {
				const item = primary_make[prop];
				const option = createOption(item.url, item.ui, type, item.primary_make);
				$elem.find('ul').append(option);
			}

			if (secondary_make.length !== 0 && primary_make.length !== 0) {
				const divider = rp$('<hr>');
				$elem.find('ul').append(divider);
			}

			for (const prop in secondary_make) {
				const item = secondary_make[prop];
				const option = createOption(item.url, item.ui, type, item.primary_make);
				$elem.find('ul').append(option);
			}
		}else {
			for (const prop in data) {
				const item = data[prop];
				const option = createOption(item.url, item.ui, type);
				$elem.find('ul').append(option);
			}
		}
	}

	function getDropDownType($elem) {
		return $elem.attr(OPTION_ATTRIBUTES.type);
	}

	function insertOptions(data, $elem) {
		// Clear out any current options
		clearOptions($elem);

		// Convert Each Item into a dropdown list item and add it to the dropdown ul
		addDataToList($elem, data);

		// Toggle Loading to False
		setDropDownLoading($elem);

		// Enable the dropdown button
		toggleDropDownDisabled($elem, true);

		//There is only one option set it to selected and trigger change event
		if(data && data.length === 1) {
			closeAllDropDowns();
			const firstOption = rp$(dropDownOptionSelector, $elem).first();
			updateNextDropDown(firstOption);
			return;
		}

		// Trigger a click on the button To Open the drop down
		$elem.find(toggleButtonSelector).click();

		// If the filter input exsists focus the input
		focusFilterInput($elem);
	}

	function closeAllDropDowns() {
		rp$(`${dropDownSelector}.open`, dropDownWrapper).removeClass('open');
	}

	function clearOptions($elem) {
		$elem
			.find(dropDownOptionSelector)
			.off('click', handleOptionClick);

		$elem.find('ul').empty();
	}

	function toggleDropDownDisabled($elem, enable = false) {
		$elem.find(toggleButtonSelector).attr('disabled', !enable).toggleClass('disabled', !enable);
	}

	function setDropDownLoading($elem) {
		$elem.toggleClass(loadingClass);
	}

	function resetDropDowns($elem) {
		const currentIndex = dropDowns.index($elem);
		const remainingDropDowns = dropDowns.toArray().filter((elem, index) => index > currentIndex);
		if (Array.isArray(remainingDropDowns)) {
			remainingDropDowns.forEach((elem) => resetOptions(rp$(elem)));
		}
	}

	// Clears the options, disables the dropdown and sets the button text back to its initial value
	function resetOptions($elem) {
		clearOptions($elem);
		toggleDropDownDisabled($elem);
		updateButtonText($elem, $elem.find(toggleButtonSelector).attr(OPTION_ATTRIBUTES.label));
	}

	// Finds the active option in an element and returns its data-value
	function getActiveValue($elem) {
		return $elem.find(`${dropDownOptionSelector}.active`).attr(OPTION_ATTRIBUTES.value);
	}
	
	// Finds the active option in an element and returns its primary-make
	function getPrimaryMake($elem) {
		return $elem.find(`${dropDownOptionSelector}.active`).attr(OPTION_ATTRIBUTES.primary_make);
	}
		
	function getDropDownPayload() {
		const data = {};
		dropDowns.each(function() {
			const $elem = rp$(this);
			data[$elem.attr(OPTION_ATTRIBUTES.type)] = getActiveValue($elem);
			if($elem.attr(OPTION_ATTRIBUTES.type) === 'make'){
					data[OPTION_ATTRIBUTES.primary_make] = getPrimaryMake($elem); 
			}
		});

		// Add default make if make dropdown hidden
		if(defaultMake) {
			data['make'] = defaultMake;
		}

		return data;
	}

	function toggleClass($target, className) {
		$target.closest(dropDownSelector).find('.' + className).removeClass(className);
		$target.addClass(className);
	}

	function getDropDownByType(type) {
		let dropDown;
		dropDowns.each(function () {
			const $elem = rp$(this);
			if($elem.attr(OPTION_ATTRIBUTES.type) === type) {
				dropDown = $elem;
				return false;
			}
		});
		return dropDown;
	}

	function getNextDropdown($elem) {
		let dropDown;
		const currentIndex = dropDowns.index($elem);
		dropDowns.each(function (index) {
			if(index === currentIndex + 1) {
				dropDown = rp$(this);
				return false;
			}
		});
		return dropDown;
	}

	function updateButtonText($elem, label) {
		$elem.find('.label-value').text(label);
	}

	function filterOptions(event) {
		const type = event.target.getAttribute(OPTION_ATTRIBUTES.type);
		const dropDown = getDropDownByType(type);
		if (!type || !dropDown) {
			return;
		}

		//Get all options in dropdown
		const $options = rp$(dropDownOptionSelector, dropDown);
		if (event.keyCode === 40) {
			// On down arrow focus the first option in the list
			$options.first().focus();
		} else if(event.keyCode === 13) {
			const $visibleOptions = rp$(`${dropDownOptionSelector}:visible`, dropDown);
			// On enter press get the first valid dropdown option and go
			const target = $visibleOptions.first();

			// Use handle option click to prevent default and propagation
			handleOptionClick(event, target[0]);
		} else {
			// Get the value of the filter input and hide all of the options that do not include the filter value
			// Replace non alphanumeric chars with a - to match the output from the service
			const value = event.target.value.replace(/\W+/g, "-").toLowerCase();
			$options.each(function () {
				const optionValue = this.getAttribute(OPTION_ATTRIBUTES.value).toLowerCase();
				rp$(this).parent().toggle(optionValue.includes(value));
			});
		}
	}

	function updateNextDropDown(target) {
		const $target = rp$(target);
		const type = $target.attr(OPTION_ATTRIBUTES.type);
		const label =  $target.attr(OPTION_ATTRIBUTES.display);
		const dropDown = getDropDownByType(type);
		const nextDropDown = getNextDropdown(dropDown);
		const isLastDropDown = !nextDropDown || !nextDropDown.length;

		toggleClass($target, 'active');
		updateButtonText(dropDown, label);
		resetDropDowns(dropDown);

		const selectedData = getDropDownPayload();

		if(isLastDropDown && !saveOnButtonClick) {
			handleSaveClick(null, selectedData);
		} else if(isLastDropDown && saveOnButtonClick) {
			//Enable Button
			saveButton.removeClass('disabled');
			saveButton.focus();
		} else {
			getNextStep(selectedData, (data) => {
				setDropDownLoading(nextDropDown);
				insertOptions(data, nextDropDown);
			});
		}
	}

	function handleOptionClick(event, element) {
		event.preventDefault();
		event.stopPropagation();
		const target = element || event.target;
		updateNextDropDown(target);
	}

	function handleDropDownClick(event) {
		setTimeout(() => {
			focusFilterInput(event.target);
		});
	}

	function focusFilterInput(elem) {
		const $elem = rp$(elem);
		$elem.closest(dropDownSelector).find(filterInputSelector).focus();
	}

	function handleSaveClick(event, data) {
		const selectedData = data || getDropDownPayload();
		setVehicle(selectedData, null, (data) => {
			handleRedirect(selectedData, data.redirect);
		});
	}

	function init() {
		dropDownWrapper = rp$(dropDownWrapperSelector);
		dropDowns = rp$('[class*="-dropdown"]', dropDownWrapper);

		rp$(toggleButtonSelector, dropDownWrapper).on('click', handleDropDownClick);
		rp$(dropDownOptionSelector, dropDownWrapper).on('click', handleOptionClick);
		rp$(filterInputSelector, dropDownWrapper).each(function () {
			rp$(this).on('keyup', filterOptions);
		});

		// Disable Drop Downs on initial load if they have no options
		dropDowns.each(function () {
			if(rp$(this).find('li').length === 0) {
				toggleDropDownDisabled(rp$(this));
			}
		});

		defaultMake = rp$(makeHiddenInputSelector, dropDownWrapper).val();
		saveButton = rp$(saveButtonSelector, dropDownWrapper);
		if(saveButton && saveButton.length > 0) {
			saveOnButtonClick = true;
			saveButton.on('click', handleSaveClick);
		}
	}

	$onReady({
		rp$,
		window,
		label: 'vehicle_picker_dropdowns',
		fn: function () { init(); },
	});
};

try {
	// Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	if (typeof __CURRENT_SCRIPT__ === 'undefined') {
		throw new Error('__CURRENT_SCRIPT__ is not defined');
	}
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}
